import "./styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Projects } from "./pages/Projects";
import { Project } from "./pages/Project";
import { Tokens } from "./pages/Tokens";
import { Token } from "./pages/Token";
import { Reserves } from "./pages/Reserves";
import { Reserve } from "./pages/Reserve";

export function App() {
    return (
        <BrowserRouter>
            <Routes>               
                {/* PUBLIC ROUTES */}
                <Route path="/" element={<Home/>} />  
                <Route path="/projects" element={<Projects/>} /> 
                <Route path="/projects/:id" element={<Project/>} /> 
                <Route path="/tokens" element={<Tokens/>} />
                <Route path="/tokens/:id" element={<Token/>} />
                <Route path="/reserves" element={<Reserves/>} />
                <Route path="/reserves/:id" element={<Reserve/>} /> 
            </Routes>
        </BrowserRouter>
    );
}

