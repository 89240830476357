import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";





import { Page } from "../components/Page";
import { GrapheneRegistryService } from "../services/GrapheneRegistryService";
import { RandomShapeIcon, MinidenticonImg } from "../components/Base";




const ProjectsList = ({ projects }) => {
    const navigate = useNavigate();
  
    return (
      <div className="flex flex-col w-full">
        <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Domain</th>
              <th>EID</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((item, index) => (
              <tr key={index} className="mb-1">
                <td>
                  <div
                    className="text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300"
                    onClick={() => navigate(`/projects/${item.id}`)}
                  >
                    {item?.project_name}
                  </div>
                </td>
                <td className="break-words max-w-[300]">
                  {item?.project_description}
                </td>
                <td>
                  <div className="break-words max-w-[140] font-mono text-xs font-bold">
                    {item?.domain}
                  </div>
                </td>
                <td>{item?.external_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};
  

const ProjectCards = ({ projects }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {projects.map((item, index) => (
          <div
            key={index}
            className="flex flex-col bg-slate-500 rounded-lg p-2 hover:bg-lime-600 hover:cursor-pointer"
            onClick={() => navigate(`/projects/${item.id}`)}
          >

            <div className="flex flex-row justify-start">
              <div className="w-[128] mr-5">
                <div className="bg-slate-400 rounded-full w-[128] h-[128]">
                  {item?.external_id && <MinidenticonImg username={item?.external_id} saturation="90" width="128" height="128" /> }
                </div>
              </div>
              <div>
                <div className="text-lg font-semibold">{item?.project_name}</div>
                <div className="text-sm font-semibold">{item?.external_id}</div>
                <div className="text-sm">{item?.project_description}</div>
                <div className="text-sm">{item?.domain}</div>
              </div>

            </div>
            
          </div>
        ))}
        </div>
    </div>
  );
};


export const Projects = ({}) => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        GrapheneRegistryService.getProjects().then((res) => {
            setProjects(res.data);
        });
    }, []);

    return (
        <Page>
            <div className="p-4 flex flex-col">
                <div className="flex flex-row">
                    <h1 className="text-2xl">Projects</h1>                      
                </div>
                <div className="flex flex-row">
                    <ProjectCards projects={projects} />
                </div>
            </div>         
        </Page>
    );
};