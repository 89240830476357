import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { Page } from "../components/Page";
import { YamlViewer } from "../components/YamlViewer"; 
import { Breadcrumb, RandomShapeIcon, MinidenticonImg } from "../components/Base";

import { GrapheneRegistryService } from "../services/GrapheneRegistryService";


export const Project = ({}) => {
    const { id } = useParams();
    const [project, setProject] = useState([]);
    const [iwaProject, setIwaProject] = useState([]);

    useEffect(() => {
        GrapheneRegistryService.getProject(id).then((res) => {
            setProject(res.data);
            setIwaProject(JSON.parse(res.data.json_blob));
        });
    }, [id]);

    return (
        <Page>
            <div className="p-4 flex flex-col">
                <Breadcrumb paths={[{"name":"All Projects","url":"/projects"},{"name":project?.project_name,"url":`/projects/${id}`, "active":false}]} />
                <div className="flex flex-row items-start mb-2">                                    
                    <div className="bg-slate-600 rounded-full w-[128] h-[128] mr-3">
                        <MinidenticonImg username={project?.external_id} saturation="90" width="128" height="128" />
                    </div>
                    <div className="flex flex-col justify-start mb-2">
                        <h1 className="text-3xl">{project?.project_name}</h1>
                        <div className="flex flex-row"><span className="text-sm font-mono">
                            {project?.domain}</span> 
                        </div>
                        <div className="flex flex-row"><span>
                            {project?.project_description}</span> 
                        </div>
                    </div>
                    
                </div>
                
                <div className="flex flex-row">
                    {project?.json_blob && 
                    <><YamlViewer hashedInfo={iwaProject}/></>}                 
                </div>
            </div>         
        </Page>
    );
};