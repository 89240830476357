import { useEffect, useState } from "react";
import { useParams,useNavigate } from 'react-router-dom';
import { Page } from "../components/Page";
import { YamlViewer } from "../components/YamlViewer"; 
import { Breadcrumb, RandomShapeIcon } from "../components/Base";

import { GrapheneRegistryService } from "../services/GrapheneRegistryService";


export const Token = ({}) => {

    const navigate = useNavigate();

    const { id } = useParams();
    const [token, setToken] = useState([]);
    const [iwaTrustline, setIwaTrustline] = useState([]);
    const [cruIds, setCruIds] = useState([]);

    useEffect(() => {
        GrapheneRegistryService.getTokenById(id).then((res) => {
            setToken(res.data);
            const iwaObject = JSON.parse(res.data?.json_blob);
            setIwaTrustline(iwaObject);
            setCruIds(iwaObject?.carbonResourceUnits);
        });
    }, [id]);

    return (
        <Page>
            {token && <div className="p-4 flex flex-col">
                <Breadcrumb paths={[{"name":"All Tokens","url":"/tokens"},{"name":token?.tokenSymbol,"url":`/tokens/${id}`, "active":false}]} />

                <div className="flex flex-row"> 
                    <div className="font-bold font-mono text-yellow-400 text-3xl">
                        {token.tokenSymbol}
                    </div>                    
                </div>
                {token?.project && <div className="flex flex-row items-baseline"> 
                    <span className="mr-2">Project:</span> 
                    <span onClick={() => navigate(`/projects/${token?.project.id}`)} className="text-lg link-common" >{token?.project?.project_name}</span>                   
                </div>}
                <div className="flex flex-row items-baseline"> 
                    <span className="mr-2">CRUs Issued:</span> 
                    <span className="font-bold font-mono text-cyan-400 text-2xl" >{token.value}</span>                   
                </div>
                <div className="flex flex-row">
                    <span className="text-lg">{token.name}</span>                      
                </div>
                <div className="flex flex-row">
                    <div className="flex flex-col">
                        <div className="flex flex-row">Provisioned Credit IDs</div>
                        <div className="flex flex-wrap">
                            {cruIds && cruIds.map((item, index) => (
                                <div key={index} className="flex flex-row">
                                    <div className="p-1 m-1 font-mono text-cyan-200 text-xs rounded-lg bg-cyan-700" >{item.credit_unique_id}</div>
                                </div>))}
                        </div>

                    </div>
                </div>
                <div className="flex flex-row">
                    {token?.json_blob && 
                    <><YamlViewer hashedInfo={iwaTrustline}/></>}                 
                </div>

            </div>}        
        </Page>
    );
};