import React, {useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import imgIcon64 from "../assets/img/centigrade_icon2_128.png";

export const Header = ({}) => {
    const navigate = useNavigate();

    return (
        <>
            <header className="h-fit bg-slate-900 text-white flex flex-col md:flex-row justify-center md:justify-between items-center md:items-start">
                <div className="text-3xl font-semibold flex flex-row justify-center md:justify-start p-2 items-top">
                    <img onClick={()=>navigate("/")}
                        src={imgIcon64} alt="graphene" 
                        className="mr-2 inline-block h-10 align-middle hover:cursor-pointer" />
                    {/* <span className="font-heading">graphene</span>
                    <span className="font-heading text-cyan-200">impact</span> */}
                </div>
                {/* <div className="flex flex-col w-[250] md:justify-end">
                    <div>
                        <div className="mr-2">
                                Login?
                        </div>
                    </div>
                </div> */}

            </header> 
 
        </>
    );
};