import create from 'zustand'
import { persist } from 'zustand/middleware'

export const useAuthStore = create(
  persist(
    (set, get) => ({
      jwt: "",
      setJwt: (jwt) => set((state) => ({ jwt: jwt })),
      isAuthorized: false,
      setIsAuthorized: (isAuthorized) => set((state) => ({ isAuthorized: isAuthorized })),
      wallet: "",
      setWallet: (wallet) => set((state) => ({ wallet: wallet })),
    }),
    {
      name: 'graphene-auth-storage', // name of the item in the storage (must be unique)
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
);
