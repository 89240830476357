import React, {useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";

import { Random, MersenneTwister19937 } from 'random-js';
import {CgSpinnerTwo} from "react-icons/cg";
import { MdBackup } from "react-icons/md";
import { BiMessageCheck } from 'react-icons/bi';
import { TbCertificate } from 'react-icons/tb';

import { minidenticon } from 'minidenticons'

export const MinidenticonImg = ({ username, saturation, lightness, ...props }) => {
  const svgURI = useMemo(
    () => 'data:image/svg+xml;utf8,' + encodeURIComponent(minidenticon(username, saturation, lightness)),
    [username, saturation, lightness]
  )
  return (<img src={svgURI} alt={username} {...props} />)
}

export const Alert = ({ background, text, children }) => {
  return (
    <div className={`p-2 flex flex-row rounded ${background} ${text} w-full`} role="alert">
      {children}
    </div>
  );
};


export const Breadcrumb = ({ paths }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {paths.map((path, index) => (
          <li key={index} className={`breadcrumb-item${index === paths.length - 1 ? ' active' : ''}`}>
            {index === paths.length - 1 ? (
              path.name
            ) : (
              <Link to={path.url}>{path.name}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}


export const RandomShapeIcon = ({ seed, size=256 }) => {
  const svgRef = useRef(null);
  

  const stringToSeedNumber = (seed) => {
    let hash = 0;
    if (seed.length === 0) return hash;
    for (let i = 0; i < seed.length; i++) {
      const char = seed.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash);
  }


  const [bgColor, setBgColor] = useState("#fbd952");


  useEffect(() => {
    if (!seed) return;

    const bgcolor = seed.substring(0, 6);
    console.log("bgcolor", `#${bgcolor}`);
    setBgColor(`#${bgcolor}`);

    const random = new Random(MersenneTwister19937.seed(stringToSeedNumber(seed)));
    const svg = svgRef.current;
    const { width, height } = svg.getBoundingClientRect();
    
    const shapeType = random.integer(1, 3);
    const shapeColor = randomColor(random);
    const shapeCount = random.integer(3, 10);
    console.log("shapeCount", shapeCount);

    for (let i = 0; i < shapeCount; i++) {
      console.log("count", i, shapeCount);
    }
    
    switch (shapeType) {
      case 1:
        for (let i = 0; i < shapeCount; i++) {
          generateTriangle(svg, width, height, shapeColor, random, i);
        }
        break;
      case 2:
        for (let i = 0; i < shapeCount; i++) {
          generateRectangle(svg, width, height, shapeColor, random);
        }
        
        break;
      case 3:     
        for (let i = 0; i < shapeCount; i++) {
          generateCircle(svg, width, height, shapeColor, random);
        }
        break;
      default:
        break;
    }
  }, [seed]);

  const randomColor = (random) => {
    const hue = random.integer(0, 360);
    const saturation = random.integer(30, 100);
    const lightness = random.integer(40, 80);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const generateCircle = (svg, width, height, color, random) => {
    const radius = random.integer(20, Math.min(width, height) / 2);
    const ox = random.integer(0, width - radius * 2);
    const oy = random.integer(0, height - radius * 2);
    const centerX = width / 2+ox;
    const centerY = height / 2+oy;
    
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circle.setAttribute('cx', centerX);
    circle.setAttribute('cy', centerY);
    circle.setAttribute('r', radius);
    circle.setAttribute('fill', color);
    
    svg.appendChild(circle);
  };

  const generateTriangle = (svg, width, height, color, random, ival=1) => {
    console.log("generateTriangle", ival);
    const sideLength = random.integer(30, Math.min(width, height));
    const ox = random.integer(0, width/2);
    const oy = random.integer(0, height/2);
    const centerX = width / 2+ox;
    const centerY = height / 2+oy;
    
    const name = "polygon";
    
    const triangle = document.createElementNS('http://www.w3.org/2000/svg', name);
    console.log("triangle", triangle);
    const points = [
      `${centerX},${centerY - sideLength / 2}`,
      `${centerX + sideLength / 2},${centerY + sideLength / 2}`,
      `${centerX - sideLength / 2},${centerY + sideLength / 2}`
    ].join(' ');
    
    triangle.setAttribute('points', points);
    triangle.setAttribute('id', `polygon${ival}`);
    triangle.setAttribute('fill', color);
    
    svg.appendChild(triangle);
  };

  const generateRectangle = (svg, width, height, color, random) => {
    const rectWidth = random.integer(30, Math.min(width, height) / 2);
    const rectHeight = random.integer(30, Math.min(width, height) / 2);
    const ox = random.integer(0,rectWidth);
    const oy = random.integer(0, rectHeight);
    const rectX = (width - rectWidth) / 2 +ox;
    const rectY = (height - rectHeight) / 2+oy;
    
    const rectangle = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rectangle.setAttribute('x', rectX);
    rectangle.setAttribute('y', rectY);
    rectangle.setAttribute('width', rectWidth);
    rectangle.setAttribute('height', rectHeight);
    rectangle.setAttribute('fill', color);
    
    svg.appendChild(rectangle);
  };


  return (
    <div style={{background: bgColor}} className={`rounded-lg m-2 flex flex-row w-[${size}] h-[${size}]`}>
      {bgColor}
      {bgColor && <svg className={`w-[${size}] h-[${size}]`} ref={svgRef} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}></svg>}
    </div>
  );
}




export const FakeProjectIcon = ({ seed, size=256 }) => {

  const [bgColor, setBgColor] = useState("#ffaa55");

  useEffect(() => {
    if (!seed) return;
    const bgcolor = seed.substring(0, 6);
    setBgColor(`#${bgcolor}`);
  }, [seed]);

  return (
    <div className={`p-2 flex flex-row rounded w-[${size}] h-[${size}]`} role="alert">
      <svg className="rounded-lg">
        <rect width={size} height={size} fill={bgColor} />
        <circle cx={50} cy={50} r={10} fill="#aa6677" />
      </svg>
    </div>
  );
};


export const HelpAlert = ({ children, helpLink }) => {
  return (
    <Alert background="bg-pink-100" text="text-slate-800">
        <div className="flex flex-row justify-end">
            <div>{children}</div>
        </div>      
    </Alert>
  );
};

export const Modal = ({ showModal, setShowModal, children }) => {
  return (
    <>{showModal && 
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
          <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
              <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
                  <div className="flex flex-col w-full justify-end">
                      <div>
                          {children}
                      </div>
                      <div className="flex flex-row justify-end items-start">
                          <button onClick={()=>setShowModal(false)} className="btn-common">Cancel</button>
                      </div>


                  </div>
                  
              </div>
          </div>
      </div>
    }</>       
  );
};

export const Badge = ({ variant, children }) => {
  return <span className={`badge badge-${variant}`}>{children}</span>;
};

export const Spinner = ({textColor="yellow-200"}) => {
  return (
    <>
    <div className="inline-block w-9 h-9 border-2 rounded-full" role="status">
        <CgSpinnerTwo className={`bg-slate-700 w-8 h-8 animate-spin text-${textColor} rounded-full`}/>
    </div>
    </>
  )
};


export const XummStatusLookup = {
  "START": {message: "TX Created.", icon: <MdBackup className="text-3xl" />},
  "SUBSCRIBED": {message: "Sent TX To Xumm, Please Sign using Xumm Wallet.", icon: <BiMessageCheck className="text-3xl" />},
  "SIGNED": {message: "TX Signed.", icon: <TbCertificate className="text-3xl" />},
};

export const JsonFileReaderComponent = ({setJson, showJson=true}) => {

  const handleFile = (e) => {
    const json = JSON.parse(e.target.result);
    setJson(json);
  }

  const handleChangeFile = (file) => {
    let fileData = new FileReader();
    fileData.onloadend = handleFile;
    fileData.readAsText(file);
  }

  return (
    <div>
      <input type="file" accept=".json" onChange={e => 
            handleChangeFile(e.target.files[0])} /> 
    </div>
  );
}

const fixedInputClass="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-slate-500 focus:z-10 sm:text-sm"

export const FormInputField = ({
    handleChange,
    value,
    labelText,
    labelFor,
    id,
    name,
    type,
    isRequired=false,
    placeholder,
    customClass=null
}) => {
    return(
        <div>
            <div>{labelText}</div>
            <label htmlFor={labelFor} className="sr-only">
              {labelText}
            </label>
            <input
              onChange={handleChange}
              value={value}
              id={id}
              name={name}
              type={type}
              required={isRequired}
              className={customClass != null ? customClass : fixedInputClass}
              placeholder={placeholder}
            />
          </div>
    )
}

export const Dropdown = ({
  options,
  onChange, 
  value,
  placeholder="Select an option"
}) => {

  const handleChange = (id) => {
    console.log("id", id);
    onChange(id);
  };
    
    const fixedInputClass = "bg-gray-200 appearance-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900"
    
    return(
      <>
        <div className="foo">
            <label className="sr-only">
              {placeholder}
            </label>
            <div className="bg-gray-200 flex flex-row justify-between rounded-sm focus:outline-none focus:ring-slate-500 focus:border-slate-500 focus:z-10 sm:text-sm">
              <select
                onChange={(e)=>handleChange(e)}
                defaultValue={value}
                className={fixedInputClass}
              >
              {options.map((item, idx) => (
                  <option key={idx} value={item.value}>{item.label}</option>
              ))}
              </select>
              {/* <AiFillCaretDown className="text-slate-500 text-2xl mt-2" /> */}
            </div>
        </div>
      </>
    )
  };

export const ErrorList = ({customClass, errors}) => {
  const fixedInputClass="sm:text-sm text-red-600 break-words max-w-[300]"

  const ulClass = "text-sm text-red-600"
  const liClass = "text-sm text-red-600"

  return (
    <div className={fixedInputClass+" "+customClass}>
      <ul className={ulClass}>
        {errors.map((error, idx) => (
          <li key={idx} className={liClass}>
          {error}
          </li>
        ))}
      </ul>
    </div>
  );
};
