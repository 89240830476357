import React, {useEffect, useState } from "react"

import { Header } from "./Header";
import { Footer } from "./Footer";


export const Page = ({
    children}) => {

    return (
        <div className="flex flex-col h-fit min-h-screen justify-between">
            <Header/>
            <main className="mb-auto h-full bg-slate-800">{children}</main>
            <Footer />
        </div>
    );
};