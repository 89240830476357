import { useEffect, useState } from "react";

import yaml from 'json-to-pretty-yaml';



/**
 * simple component to display of hashed data
 * @param {*} param0 
 * @returns 
 */
export const YamlViewer = ({hashedInfo}) => {

    const [prettyYaml, setPrettyYaml] = useState(yaml.stringify(hashedInfo));

    return (
        <>
            {hashedInfo && 
             <div className="flex flex-col w-full">       
                <div className="bg-slate-700 p-2 w-full">
                    <pre className="flex flex-row w-full text-sm text-yellow-200 overflow-x-auto">
                        {prettyYaml}
                    </pre>
                </div>
            </div>}
        </>
    );
  };


  