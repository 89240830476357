import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Page } from "../components/Page";
import imgIcon64 from "../assets/img/centigrade_icon2_128.png";

import {BsFillTreeFill} from "react-icons/bs";
import {GiTwoCoins} from "react-icons/gi";
import {FaPiggyBank} from "react-icons/fa";

import { GrapheneRegistryService } from "../services/GrapheneRegistryService";

const TokensList = ({ tokens }) => {
    const navigate = useNavigate();
  
    return (
      <div className="flex flex-col w-full">
        <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
          <thead>
            <tr>
                <th>Symbol</th>
                <th>Name</th>
                <th>IWA ID</th>
                <th>Project</th>
                {/* <th>Description</th>
                <th>Domain</th>
                <th>EID</th> */}
            </tr>
          </thead>
          <tbody>
            {tokens && tokens.map((item, index) => (
              <tr key={index} className="mb-1">
                <td>
                    <span className="text-lg font-mono font-bold div-link text-yellow-400 hover:text-yellow-300"
                        onClick={() => navigate(`/tokens/${item.id}`)}
                    >
                        {item?.tokenSymbol}
                    </span>

                </td>
                <td>
                  <div
                    className="text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300"
                    onClick={() => navigate(`/tokens/${item.id}`)}
                  >
                    {item?.name}
                  </div>
                </td>
                <td className="break-words max-w-[300]">
                  {item?.iwa_id}
                </td>
                <td className="break-words max-w-[300]">
                    {item?.project && <div
                    className="text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300"
                    onClick={() => navigate(`/projects/${item?.project.id}`)}>{item?.project?.project_name}</div>}
                        
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};

export const Tokens = ({}) => {
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        GrapheneRegistryService.getTokens().then((res) => {
            setTokens(res.data);
        });
    }, []);

    return (
        <Page>
            <div className="p-4 flex flex-col">
                <div className="flex flex-row">
                    <h1 className="text-2xl">Tokens</h1>                      
                </div>
                <div className="flex flex-row">
                    <TokensList tokens={tokens}/>
                </div>
            </div>         
        </Page>
    );
};