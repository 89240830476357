import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { Page } from "../components/Page";
import { YamlViewer } from "../components/YamlViewer"; 
import { Breadcrumb, RandomShapeIcon } from "../components/Base";
import { GiBlackBridge } from "react-icons/gi";
import { GrapheneRegistryService } from "../services/GrapheneRegistryService";



export const Reserve = ({}) => {

    const { id } = useParams();
    const [reserve, setReserve] = useState();
    const [reserveObject, setReserveObject] = useState();
    

    useEffect(() => {
        GrapheneRegistryService.getReserveById(id).then((res) => {
            setReserve(res.data);
            let reserveObject = {...res.data};
            reserveObject.tokenSymbol = reserveObject.tlcs.tokenSymbol;
            delete reserveObject.tlcs;
            setReserveObject(reserveObject);
        });
    }, [id]);


    return (
        <Page>
            {reserveObject && <div className="p-4 flex flex-col">
                <Breadcrumb paths={[{"name":"All Reserves","url":"/reserves"},{"name":reserveObject?.name,"url":`/reserves/${id}`, "active":false}]} />
                <div className="flex flex-row">
                    <h1 className="font-bold text-yellow-400 text-2xl"> {reserveObject.tokenSymbol} {reserveObject.amount}</h1>                      
                </div>
                <div className="flex flex-row justify-start">
                      
                    <GiBlackBridge className="text-2xl text-lime-400 mx-2"/> 
                    <span className="font-bold text-lg">
                        {reserveObject.provider}
                    </span>                   
                </div>
                <div className="flex flex-row">
                    <h1 className="text-lg">{reserveObject.name}</h1>                      
                </div>
                <div className="flex flex-row">
                    {reserveObject?.name && <><YamlViewer hashedInfo={reserveObject}/></>}            
                </div>
            </div>}        
        </Page>
    );
};