
import { useNavigate } from "react-router-dom";

import { Page } from "../components/Page";
import imgIcon64 from "../assets/img/centigrade_icon2_128.png";

import {BsFillTreeFill} from "react-icons/bs";
import {GiTwoCoins} from "react-icons/gi";
import {FaPiggyBank} from "react-icons/fa";


export const Home = ({}) => {

    const navigate = useNavigate();

    return (
        <Page>
            <div>
                <div className="font-semibold flex flex-col items-center justify-center md:justify-start p-2 items-top">
                    <img src={imgIcon64} alt="centigrade" className="w-[512] mb-3"/>
                
                    <div className="text-3xl mb-3 text-center">
                        The centigrade registry is a public blockchain registry for carbon projects, CRU tokens and tCO2 reserve blocks.
                    </div>
                    <div className="flex flex-wrap text-xl justify-center">
                        <div 
                        onClick={()=>navigate("/projects")}
                        className="flex flex-col items-center justify center m-1 w-[256] h-[256] rounded-lg bg-slate-500 p-11 hover:bg-lime-600 hover:cursor-pointer">
                            <div>Browse Projects</div>
                            <BsFillTreeFill className="m-2 w-[128] h-[128]"/>
                        </div>
                        <div 
                        onClick={()=>navigate("/tokens")}
                        className="flex flex-col items-center justify center m-1 w-[256] h-[256] rounded-lg bg-slate-500 p-11 hover:bg-lime-600 hover:cursor-pointer">
                            <div>Browse Tokens</div>
                            <GiTwoCoins className="m-2 w-[128] h-[128]"/>
                        </div>
                        <div 
                        onClick={()=>navigate("/reserves")}
                        className="flex flex-col items-center justify center m-1 w-[256] h-[256] rounded-lg bg-slate-500 p-11 hover:bg-lime-600 hover:cursor-pointer">
                            <div>Browse Reserves</div>
                            <FaPiggyBank className="m-2 w-[128] h-[128]"/>
                        </div>

                    </div>
                </div>
            </div>
            
        </Page>
    );
};