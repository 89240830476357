import Axios from 'axios';

import { apiConfig, webBaseUrl } from "../env";

Axios.defaults.withCredentials = false;
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';

/**
 * only uses public endpoints
 */
export const GrapheneRegistryService = {  
    async getInfo () {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/registry/info`);
    },
    async getProjects () {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/registry/projects`);
    },  
    async getProject (id) {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/registry/projects/${id}`);
    },  
    async getTokens () {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/registry/tokens`);
    },  
    async getTokenById (id) {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/registry/tokens/id/${id}`);
    },   
    async getReserves () {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/registry/reserves`);
    },  
    async getReserveById (id) {    
        return await Axios.get(`${apiConfig().apiBaseUrl}/registry/reserves/${id}`);
    },   
};

